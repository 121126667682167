<template>
    <v-container>
        <v-card outlined max-width="850">
            <v-card-title :class="$classes.cardTitle">
                    <span class="font-weight-bold title mr-5 mb-3 d-flex align-center">Notifications</span>
            </v-card-title>
            <v-container>
                <v-card-text class="pa-1">
                        <v-row>
                            <v-col>
                                <h4 class="primary--text font-weight-bold">Choose the users</h4>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col
                                v-for="(user, i) in users"
                                :key="i"
                                cols="12"
                                md="6"
                                lg="3"
                                xl="3"
                            >
                                    <v-checkbox
                                        v-model="data.selected"
                                        :label="user.label"
                                        :value="user.value"
                                    ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h4 class="primary--text font-weight-bold mb-3">Enter the message</h4>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-textarea
                                name="input-7-1"
                                outlined
                                label="Message"
                                auto-grow
                                v-model="data.message"
                            ></v-textarea>
                        </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="sendPushNotification" :loading="status.sending" :disabled="!data.selected.length" class="mb-5 primary" depressed>Notify</v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    name: 'Notifications',
     data () {
      return {
        users: [
            {
                label: 'Dietitians',
                value: 'dietitian'
            },
            {
                label: 'Nutritionists',
                value: 'nutritionist'
            },
            {
                label: 'Naturopaths',
                value: 'Naturopath'
            },
            {
                label: 'Personal Trainer',
                value: 'personal_trainer'
            },
            {
                label: 'Members',
                value: 'member'
            },
            {
                label: 'Admins',
                value: 'admin'
            }
        ],
      }
    },
    computed: {
        ...mapState({
            data: state => state.notification.data,
            status: state => state.notification.status
        })
    },
    methods: {
        ...mapActions('notification', ['sendPushNotification'])
    }
}
</script>

<style>

</style>